<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6"
  >
    <circle cx="12.5" cy="12.5" r="11.75" stroke="white" stroke-width="1.5" />
    <path
      d="M4 20.3348C6.2304 18.2653 9.21744 17 12.5 17C15.7826 17 18.7696 18.2653 21 20.3348"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M4 4.00019C6.2304 6.06966 9.21744 7.33496 12.5 7.33496C15.7826 7.33496 18.7696 6.06966 21 4.00019"
      stroke="white"
      stroke-width="1.5"
    />
    <line x1="12.75" y1="1" x2="12.75" y2="25" stroke="white" stroke-width="1.5" />
    <line y1="12.25" x2="24" y2="12.25" stroke="white" stroke-width="1.5" />
  </svg>
</template>
